(function($){

  $(window).load(function(){
    $('.view-projects').addClass('ms-ready');
    var $container = $(".ms-container");
//    $container.hide(); <- we do this in css
    $container.fadeIn(1000).masonry({ "columnWidth": ".ms-item", "gutter": 50, "itemSelector": ".ms-item"});
    $(".ms-item").addClass("ms-processed");
    var $pager = $(".view-projects .item-list");
    $pager.fadeIn(1000).find(".pager-next").prepend("<span class=\"icon\" />").find('a').on("click",function(){
      $pager.addClass("loading");
    });
  });

  Drupal.behaviors.viewsLoadMoreAppended = {
    attach: function(context, settings){
      if(typeof context.selector != "undefined") {
        var appended = $(context.selector).find(".ms-item:not(.ms-processed)");
        $(appended).hide();
        $(context.selector).imagesLoaded(function(){
          setTimeout(function(){
            $(appended).show().addClass("ms-processed");
            $(".ms-container").masonry("appended",$(appended));
            var $pager = $(".view-projects .item-list");
            $pager.removeClass("loading").find(".pager-next").prepend("<span class=\"icon\" />").find('a').on("click",function(){
              $pager.addClass("loading");
            });
          }, 1000);
        });
      }
    }
  }

}(jQuery));